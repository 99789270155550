import { GET_COMPANY, GET_COMPANY_LIST, GET_COMPANY_LOGS } from './-getter-types';
import { useCompaniesStore } from '~/stores/companies';
import { type Company } from '~/models/Company';
import { type CompanyLog } from '~/models/CompanyLog';
import { type List } from '~/models/List';

const Getters = {
    [GET_COMPANY]: (): Company | null => {
        const companiesStore = useCompaniesStore();

        return companiesStore.company;
    },
    [GET_COMPANY_LIST]: (): List[] | null => {
        const companiesStore = useCompaniesStore();

        return companiesStore.companyList;
    },
    [GET_COMPANY_LOGS]: (): CompanyLog[] | null => {
        const companiesStore = useCompaniesStore();

        return companiesStore.companyLogs;
    },
};

export default Getters;
