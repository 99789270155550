import { SET_COMPANY, SET_COMPANY_LIST, SET_COMPANY_LOGS } from './-mutation-types';
import { useCompaniesStore } from '~/stores/companies';
import { type Company } from '~/models/Company';
import { type CompanyLog } from '~/models/CompanyLog';
import { type List } from '~/models/List';

const Mutations = {
    [SET_COMPANY](payload: Company | null) {
        const companiesStore = useCompaniesStore();
        companiesStore.company = payload;
    },
    [SET_COMPANY_LIST](payload: List[] | null) {
        const companiesStore = useCompaniesStore();
        companiesStore.companyList = payload;
    },
    [SET_COMPANY_LOGS](payload: CompanyLog[] | null) {
        const companiesStore = useCompaniesStore();
        companiesStore.companyLogs = payload;
    },
};

export default Mutations;
