import { type Company } from '~/models/Company';
import { type CompanyLog } from '~/models/CompanyLog';
import { type List } from '~/models/List';

export type CompanyState = {
    company: Company | null;
    companyLogs: CompanyLog[] | null;
    companyList: List[] | null;
}

export const defaultStateData = (): CompanyState => ({
    company: null,
    companyList: null,
    companyLogs: null,
});

export const DefaultState = (): CompanyState => defaultStateData();

export type State = ReturnType<typeof DefaultState>
