import { SET_COMPANY, SET_COMPANY_LIST, SET_COMPANY_LOGS } from './-mutation-types';
import { useCompaniesStore } from '~/stores/companies';
import CompanyService from '~/services/api/crm/CompanyService';
import { type Company } from '~/models/Company';

const Actions = {
    async fetchCompaniesByType(companyType?: number) {
        const companiesStore = useCompaniesStore();
        const companyService = new CompanyService();
        const response = await companyService.fetchCompaniesByType(companyType);

        companiesStore[SET_COMPANY_LIST](response);
    },
    async fetchCompany(companyId: number) {
        const companiesStore = useCompaniesStore();
        const companyService = new CompanyService();
        const response = await companyService.fetchCompany(companyId);

        companiesStore[SET_COMPANY](response);
    },
    async fetchCompanyLogs(companyId: number) {
        const companiesStore = useCompaniesStore();
        const companyService = new CompanyService();
        const response = await companyService.fetchCompanyLogs(companyId);

        companiesStore[SET_COMPANY_LOGS](response);
    },
    setCompany(company: Company | null) {
        const companiesStore = useCompaniesStore();
        companiesStore[SET_COMPANY](company);
    },
};

export default Actions;
